// src/data/seiscanCodeData.js

const seiscanCodeData = {
  "SeiScan Queries": [
    {
      title: "Query Token ID Info on SeiScan",
      description: "You can query token ID information from the collection address directly on SeiScan using the following query.",
      code: `
{
  "all_nft_info": {
    "token_id": "420"
  }
}
`,
      link: "https://www.seiscan.app/pacific-1/interact-contract?selectedType=query&contract=sei1c7tj004asc9n6zhkk950u0nvxewwswanvg95aqazqs29q3l6nq9sqtrtwl&msg=ewogICJhbGxfbmZ0X2luZm8iOiB7fQp9", // Added link to SeiScan URL
      linkText: "View on SeiScan", // Text for the link button
    },
    {
      title: "Send ERC-1155 Tokens",
      description: "You can send CWW-1155 tokens via SeiScan using the following query from the collection page.",
      code: `
{
  "send": {
    "from": "from_address",
    "to": "to_address",
    "token_id": "1",
    "amount": "1"
  }
}
`,
      link: "https://www.seiscan.app/pacific-1/interact-contract", // Add a relevant link here if applicable
      linkText: "View on SeiScan", // Text for the link button
    },
    {
      title: "Send CW-721 Tokens",
      description: "You can send CW-721 tokens via SeiScan using the following query from the collection page.",
      code: `
{
  "transfer_nft": {
    "recipient": "to_address",
    "token_id": "token_id_number"
  }
}
`,
      link: "https://www.seiscan.app/pacific-1/interact-contract", // Add a relevant link here if applicable
      linkText: "View on SeiScan", // Text for the link button
    },
  ],
};

export default seiscanCodeData;
