import s3Logo from '../icons/S3.png';
import webumpLogo from '../icons/WeBump.png';
import block24Logo from '../icons/Block24.png';
import crossover from '../icons/Crossover.png';
import korpdog from '../icons/KorpDogs.png';
import fuckers from '../icons/Fuckers.png';
import remos from '../icons/Remos World.png';
import barbreakers from '../icons/Bar Breakers.png';
import ccLogo from '../icons/Logo.png';
import katzLogo from '../icons/Katz.png';
import cappyLogo from '../icons/CAPPYS.png';

export const communitiesData = [
  {
    name: "Bar Breakers",
    description: "Bar Breakers, formerly SEIndicate, honors the creative spirit on the Sei Network with a collection that commemorates shared experiences.",
    features: [
      "Represents the memories and milestones of the Sei Network community.",
      "Each NFT brick is crafted by a Sei community member.",
      "A collaborative project celebrating the journey on Sei Network."
    ],
    website: "https://seindicate.com/",
    twitter: "https://x.com/TheBarBreakers",
    discord: "https://discord.gg/seindicate",
    logo: barbreakers
  },
  {
    name: "Block 24",
    description: "Block 24 is a community created with the primary goal of onboarding Nigerians into the world of crypto and SEI.",
    features: [
      "Founded by active Sei community members Midas and Cryptostew.",
      "Produces educational video content to enhance crypto understanding.",
      "Hosts 'Block Talk,' a weekly X space discussing various topics.",
      "Organizes IRL (In Real Life) events to engage and educate the community."
    ],
    website: "TBA",
    twitter: "https://x.com/Block24_",
    discord: "https://discord.com/invite/PuT5Y4SqMx",
    logo: block24Logo
    },

    {
        name: "CAPPYS",
        description: "The unexpected, overweight superheroes of Sei.",
        features: [ ],
        website: "TBA",
        twitter: "https://x.com/CappysNFT",
        discord: "https://discord.com/invite/cappysnftw",
        logo: cappyLogo
    },

  {
    name: "Crafty Canines",
    description: "The first NFT on the EVM side of SEI and the founders of SEIGuide.xyz.",
    features: [
      "Cute art, fun tools.",
	  "Access to the best resources on Sei."
    ],
    website: "https://www.craftycanines.xyz/",
    twitter: "https://x.com/crafty_canines",
    discord: "https://discord.com/invite/NRmRcmJV6w",
    logo: ccLogo
  },
  
  {
    name: "Crossover Studios",
    description: "A Web3 creative studio focusing on developing intellectual property through online content creation.",
    features: [
      "What began as a few lighthearted animated videos on Twitter has rapidly expanded, with intentions to collaborate with additional creators and establish a small community of creators.",
    ],
    website: "https://crossoverstudio.co/",
    twitter: "https://x.com/crossover_xo",
    discord: "https://discord.gg/rXVgq6swQx",
    logo: crossover
  },
  {
    name: "Fuckers",
    description: "THE COMMUNITY’S COMMUNITY!!!",
    features: [
      "YRRRRR!!!",
      "FUCK AROUND N FIND OUT",
      "[REDACTED] OF [REDACTED]"
    ],
    website: "https://yrrrrrlabz.vercel.app/",
    twitter: "https://x.com/fuckersforlife",
    discord: "https://discord.com/invite/8PS9AaaqK3",
    logo: fuckers
  },
 {
    name: "Katz",
    description: "Katz is an OG Sei project run by a dedicated developer.",
    features: [
      "NFT Staking for $MEOW token",
      "Permissionless raffle platform.",
    ],
    website: "https://www.katz.website/",
    twitter: "https://x.com/KatzNFTs",
    discord: "https://discord.gg/fT3KKGM9GD",
    logo: katzLogo
  }, 
  
  
  {
    name: "KorpDogs",
    description: "KorpDogs is an artist-led community that's raising the profile of art on Sei with a focus on UK street art.",
    features: [
      "Learn how to draw in our weekly educational Discord stages.",
      "Hosts IRL events both in the traditional artist space and digital communities."
    ],
    website: "https://korpdogs.xyz/",
    twitter: "https://x.com/korpdogsnft",
    discord: "https://discord.gg/korpdogs",
    logo: korpdog
  },
  {
    name: "Remo's World",
    description: "Remo's World by Joey Tadiar blends physical and digital art, storytelling, and collectibles, inspiring creativity through Web3.",
    features: [
      "Stake NFTs to earn points for digital and physical collectibles.",
      "Children's Book with QR codes to unlock digital collectibles and NFTs.",
      "Offers rare and exclusive art toys, oil paintings, and limited-edition clothing."
    ],
    website: "https://www.remosworld.xyz/",
    twitter: "https://x.com/remosworld",
    discord: "https://discord.gg/fhcCYJprJR",
    logo: remos
  },
   {
    name: "WeBump",
    description: "WeBump collaborates with Lighthouse to support developer teams and creators on the Sei blockchain by providing open-source smart contracts.",
    features: [
      "Offers open-source smart contracts for NFT creation on Sei.",
      "Lighthouse protocol and toolset enhance the NFT creation process.",
      "Focused on making NFT creation more accessible and efficient."
    ],
    website: "https://webump.xyz/",
    twitter: "https://x.com/webump_",
    discord: "https://discord.gg/KX5kMUv4Tr",
    logo: webumpLogo
  }
];
