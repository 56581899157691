import dragonSwapLogo from '../icons/Dragon Swap.png';
import yakaLogo from '../icons/Yaka Finance.png';
import yeiLogo from '../icons/Yei Finance.png';
import jellyLogo from '../icons/Jellyverse.png';
import filamentLogo from '../icons/Filament.png';
import carbonDefiLogo from '../icons/Carbon DeFi.png'; 

export const defiData = [
    {
        name: "Carbon DeFi",
        logo: carbonDefiLogo,
        description: "Carbon DeFi is an on-chain trading protocol enabling automated strategies with customizable limit and range orders.",
        features: [
            "Custom spreads between buy and sell orders.",
            "MEV-resistant, irreversible executions.",
            "Adjustable strategies directly on-chain."
        ],
        website: "https://www.carbondefi.xyz/",
        docs: "https://docs.carbondefi.xyz/",
        twitter: "https://x.com/carbondefixyz"
    },
  {
    name: "DragonSwap",
    logo: dragonSwapLogo,
    description: "Native Decentralized Exchange (DEX) on Sei V2 leveraging the first parallelized EVM for enhanced performance.",
    features: [
      "Symbolized by a dragon, representing growth, strength, wisdom, and authority, inspired by Asian lore.",
      "Premier EVM DEX on Sei Network offering a user-friendly and high-performance trading platform.",
      "Open-source framework simplifies liquidity and spot trading for confident and unconstrained trading."
    ],
    website: "https://dragonswap.app/",
    docs: "https://docs.dragonswap.app/dragonswap",
    twitter: "https://x.com/dragonswap_dex"
  },
  {
    name: "Filament Finance",
    logo: filamentLogo,
    description: "Hybrid Perpetual DEX combining off-chain order books with on-chain liquidity pools for enhanced liquidity and capital efficiency.",
    features: [
      "Compartment-Based Liquidity Pools (COMB) improve liquidity management by compartmentalizing assets within a unified stablecoin vault.",
      "Efficient trading through the integration of an off-chain order book and on-chain liquidity, ideal for large or partially unfulfilled orders.",
      "Socializes borrowing fees among traders and includes automated rebalancing to optimize capital distribution.",
      "Advanced architecture, including Single-Asset Liquidity Pool (SALP) and sophisticated order routing system."
    ],
    website: "https://filament.finance/",
    docs: "https://docs.filament.finance/",
    twitter: "https://x.com/FilamentFinance"
  },
  {
    name: "JellyVerse",
    logo: jellyLogo,
    description: "Jellyverse is a community-governed ecosystem aiming to lead the next generation of DeFi (DeFi 3.0).",
    features: [
      "JLY token serves as the governance and utility token, providing staking, liquidity mining, and more.",
      "JellySwap: A decentralized exchange within Jellyverse with advanced features like WeightedPools and decentralized indices.",
      "JellyStake introduces innovative Staking-NFTs for a streamlined staking experience.",
      "jAssets system allows trading of decentralized assets (jAssets) while ensuring security and tracking real-world asset prices."
    ],
    website: "https://jellyverse.org/",
    docs: "https://docs.jellyverse.org/",
    twitter: "https://x.com/jlyvrs"
  },
  {
    name: "Yaka Finance",
    logo: yakaLogo,
    description: "Native liquidity engine for Sei, designed as a ve(3,3)-styled DEX and launchpad.",
    features: [
      "Unlocks Sei's DeFi potential by leveraging its twin-turbo consensus for rapid transactions.",
      "Strategic collaboration with Sei Foundation to maximize DeFi innovation through liquidity grants.",
      "Aims to be Sei's central hub for DeFi liquidity, focusing on real-world assets.",
      "Positions itself to redefine DeFi on Sei by enhancing Sei's innate strengths."
    ],
    website: "https://yaka.finance/",
    docs: "https://yaka.gitbook.io/yaka-finance",
    twitter: "https://x.com/YakaFinance"
  },
  {
    name: "Yei Finance",
    logo: yeiLogo,
    description: "Pioneering decentralized, non-custodial money market protocol on the Sei network offering flexible financial solutions.",
    features: [
      "Deposit assets to earn passive income or borrow through traditional or flash loan mechanisms.",
      "Flash loans allow borrowing without collateral, provided repayment is within a single transaction.",
      "High Efficiency Mode (E-mode) maximizes capital efficiency for correlated assets, offering higher borrowing capacity.",
      "Isolation Mode enables isolated pools with tailored risk management, limiting potential disruptions."
    ],
    website: "https://app.yei.finance/",
    docs: "https://docs.yei.finance/",
    twitter: "https://x.com/YeiFinance"
  }
];
