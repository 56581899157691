import alterEcoLogo from '../icons/Alter Eco.png';
import barBreakersLogo from '../icons/Bar Breakers.png';
import binanceLogo from '../icons/Binance.png';
import bitgetLogo from '../icons/Bitget.png';
import block24Logo from '../icons/Block24.png';
import bybitLogo from '../icons/ByBit.png';
import coinbaseLogo from '../icons/Coinbase.png';
import crossoverLogo from '../icons/Crossover.png';
import decoderLogo from '../icons/decoder.png';
import discordLogo from '../icons/Discord.png';
import dragonSwapLogo from '../icons/Dragon Swap.png';
import filamentLogo from '../icons/Filament.png';
import frenseiLogo from '../icons/Frensei.png';
import fuckersLogo from '../icons/Fuckers.png';
import gateIOLogo from '../icons/GateIO.png';
import grilledLogo from '../icons/Grilled Onion.png';
import jellyverseLogo from '../icons/Jellyverse.png';
import korpDogsLogo from '../icons/KorpDogs.png';
import kucoinLogo from '../icons/Kucoin.png';
import logoLogo from '../icons/Logo.png';
import mediumLogo from '../icons/Medium.png';
import mexcLogo from '../icons/MEXC.png';
import mrktLogo from '../icons/MRKT.png';
import palletLogo from '../icons/Pallet.png';
import remosWorldLogo from '../icons/Remos World.png';
import rhinoStakeLogo from '../icons/RhinoStake.png';
import s3Logo from '../icons/S3.png';
import seiLogo from '../icons/SEI.png';
import seiScanLogo from '../icons/SeiScan.png';
import seiTraceLogo from '../icons/Seitrace.png';
import seiwhatLogo from '../icons/W3BTHR33.png';
import simpleSwapLogo from '../icons/SimpleSwap.png';
import stargateLogo from '../icons/Stargate.png';
import symbiosisLogo from '../icons/Symbiosis.png';
import twitterLogo from '../icons/Twitter.png';
import ufoLogo from '../icons/UFO.png';
import usdcLogo from '../icons/USDC.png';
import usdtLogo from '../icons/USDT.png';
import weBumpLogo from '../icons/WeBump.png';
import yakaFinanceLogo from '../icons/Yaka Finance.png';
import yeiFinanceLogo from '../icons/Yei Finance.png';
import cappyRadioLogo from '../icons/Cappy Radio.png';
import cjLogo from '../icons/CJ.png';
import dexLogo from '../icons/DEX Screener.png';
import seiyanLogo from '../icons/Seiyan.png';
import cgLogo from '../icons/Coingecko.png';
import gonadLogo from '../icons/Gonad.png';
import mmLogo from '../icons/MetaMask.png';
import compLogo from '../icons/Compass.png';
import greenityLogo from '../icons/Sei Something.png';
import meLogo from '../icons/Magic Eden.png';
import ifyouSeiLogo from '../icons/ifYouSei.png';
import krakenLogo from '../icons/Kraken.png';
import whaleLogo from '../icons/Sei Whale.png';
import katzLogo from '../icons/Katz.png';
import rexLogo from '../icons/Inspector.png';
import popoLogo from '../icons/POPO.png';
import seiyunLogo from '../icons/Seiyun.png';
import milliLogo from '../icons/MILLI.png';
import ballzLogo from '../icons/SeiBallz.png';
import shishoLogo from '../icons/Shisho.png';
import slowLogo from '../icons/SLOW.png';
import happyLogo from '../icons/Happy Ape.png';
import CHIPSLogo from '../icons/CHIPS.png';
import UMILogo from '../icons/UMI.png';
import Oolong from '../icons/Oolong.png';
import ShenronLogo from '../icons/Shenron.png';
import wilsonLogo from '../icons/WILSON.png';
import waitLogo from '../icons/WAIT.png';
import carbonDefiLogo from '../icons/Carbon DeFi.png';
import cappyLogo from '../icons/CAPPYS.png';

export const mediaKitCategoriesData = {
    Coins: [
    { name: "CHIP$ on SEI", logo: CHIPSLogo, link: CHIPSLogo },
    { name: "gonad", logo: gonadLogo, link: gonadLogo },
    { name: "Happy Ape", logo: happyLogo, link: happyLogo },
    { name: "Inspector", logo: rexLogo, link: rexLogo },
	{ name: "LORD SHISHO", logo: shishoLogo, link: shishoLogo },
    { name: "MILLI", logo: milliLogo, link: milliLogo },
    { name: "Oolong", logo: Oolong, link: Oolong },
	{ name: "Popo The Cat", logo: popoLogo, link: popoLogo },
    { name: "SEI", logo: seiLogo, link: seiLogo },
    { name: "SeiBallz", logo: ballzLogo, link: ballzLogo,},
	{ name: "SeiWhale", logo: whaleLogo, link: whaleLogo },
    { name: "SEIYAN", logo: seiyanLogo, link: seiyanLogo } ,
    { name: "SEIYUN COIN", logo: seiyunLogo, link: seiyanLogo },
    { name: "Shenron", logo: ShenronLogo, link: ShenronLogo },
    { name: "SLOW", logo: slowLogo, link: slowLogo },
    { name: "UMI", logo: UMILogo, link: UMILogo },
    { name: "USDC", logo: usdcLogo, link: usdcLogo },
    { name: "USDT", logo: usdtLogo, link: usdtLogo },
    { name: "WAIT", logo: waitLogo, link: waitLogo },
    { name: "WILSON", logo: wilsonLogo, link: wilsonLogo },
    ],
    Community: {
    "NFT Communities": [
      { name: "Bar Breakers", logo: barBreakersLogo, link: barBreakersLogo },
      { name: "Block24", logo: block24Logo, link: block24Logo },
      { name: "Cappy", logo: cappyLogo, link: cappyLogo },
	  { name: "Crafty Canines", logo: logoLogo, link: logoLogo },
      { name: "Crossover", logo: crossoverLogo, link: crossoverLogo },
      { name: "Fuckers", logo: fuckersLogo, link: fuckersLogo },
	  { name: "Katz", logo: katzLogo, link: katzLogo },
      { name: "KorpDogs", logo: korpDogsLogo, link: korpDogsLogo },
      { name: "Remo's World", logo: remosWorldLogo, link: remosWorldLogo },
      { name: "S3", logo: s3Logo, link: s3Logo },
      { name: "WeBump", logo: weBumpLogo, link: weBumpLogo },
    ],
    "Twitter Spaces": [
	  { name: "Alter Eco", logo: alterEcoLogo, link: alterEcoLogo },
	  { name: "Cappy Radio", logo: cappyRadioLogo, link: cappyRadioLogo },
	  { name: "Daily S3X", logo: s3Logo, link: s3Logo },
	  { name: "ifYouSei", logo: ifyouSeiLogo, link: ifyouSeiLogo },
	  { name: "Layers", logo: grilledLogo, link: grilledLogo },
      { name: "Sei Roundup", logo: cjLogo, link: cjLogo},
	  { name: "Sei Something", logo: greenityLogo, link: greenityLogo },
      { name: "Sei What", logo: seiwhatLogo, link: seiwhatLogo },
    ]
  },
    Defi: [
    { name: "Carbon DeFi", logo: carbonDefiLogo, link: carbonDefiLogo },
    { name: "Compass", logo: compLogo, link: compLogo },
    { name: "Coingecko", logo: cgLogo, link: cgLogo },
	{ name: "DEX Screener", logo: dexLogo, link: dexLogo },
    { name: "Dragon Swap", logo: dragonSwapLogo, link: dragonSwapLogo },
    { name: "Filament", logo: filamentLogo, link: filamentLogo },
    { name: "Jellyverse", logo: jellyverseLogo, link: jellyverseLogo },
    { name: "MetaMask", logo: mmLogo, link: mmLogo },
    { name: "Yaka Finance", logo: yakaFinanceLogo, link: yakaFinanceLogo },
    { name: "Yei Finance", logo: yeiFinanceLogo, link: yeiFinanceLogo },
  ],
  Exchanges: [
    { name: "Binance", logo: binanceLogo, link: binanceLogo },
    { name: "Bitget", logo: bitgetLogo, link: bitgetLogo },
    { name: "ByBit", logo: bybitLogo, link: bybitLogo },
    { name: "Coinbase", logo: coinbaseLogo, link: coinbaseLogo },
    { name: "GateIO", logo: gateIOLogo, link: gateIOLogo },
	{ name: "Kraken", logo: krakenLogo, link: krakenLogo },
    { name: "Kucoin", logo: kucoinLogo, link: kucoinLogo },
    { name: "MEXC", logo: mexcLogo, link: mexcLogo },
    { name: "SimpleSwap", logo: simpleSwapLogo, link: simpleSwapLogo },
  ],
  Infrastructure: [
    { name: "Decoder", logo: decoderLogo, link: decoderLogo },
    { name: "RhinoStake", logo: rhinoStakeLogo, link: rhinoStakeLogo },
    { name: "SeiScan", logo: seiScanLogo, link: seiScanLogo },
    { name: "SeiTrace", logo: seiTraceLogo, link: seiTraceLogo },
    { name: "Stargate", logo: stargateLogo, link: stargateLogo },
    { name: "Symbiosis", logo: symbiosisLogo, link: symbiosisLogo },
    { name: "UFO", logo: ufoLogo, link: ufoLogo },
  ],
  NFTs: [
    { name: "Frensei", logo: frenseiLogo, link: frenseiLogo },
    { name: "Magic Eden", logo: meLogo, link: meLogo },
    { name: "MRKT", logo: mrktLogo, link: mrktLogo },
    { name: "Pallet", logo: palletLogo, link: palletLogo },
  ],
  Socials: [
    { name: "Discord", logo: discordLogo, link: discordLogo },
    { name: "Medium", logo: mediumLogo, link: mediumLogo },
    { name: "Twitter", logo: twitterLogo, link: twitterLogo },
  ],
};

export default mediaKitCategoriesData;
