import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { homeData } from '../data/homeData'; // Importing the JSON data for Home content
import SEILogo from '../icons/SEI.png'; // Import SEI logo
import specsImage from '../images/specs.png'; // Import the specs.png image

const cardHoverVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const HomeCard = ({ title, description, isSecondCard, onOpenImage }) => (
  <motion.div
    initial={{ opacity: 0, y: 50, scale: 0.9 }}
    animate={{ opacity: 1, y: 0, scale: 1 }}
    exit={{ opacity: 0, y: -50, scale: 0.9 }}
    whileHover="hover"
    variants={cardHoverVariants}
    className="bg-gray-900 p-6 md:p-8 rounded-lg shadow-lg flex flex-col items-center space-y-4 mb-8 w-full max-w-3xl mx-auto"
  >
    <div className="text-left w-full">
      <h3 className="text-2xl font-bold mb-4 text-center">{title}</h3>
      {isSecondCard ? (
        <ul className="list-disc list-inside text-sm md:text-gray-400 text-left space-y-2">
          {description.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ) : (
        <p className="text-sm md:text-gray-400 text-center whitespace-pre-line">{description}</p>
      )}
      {isSecondCard && (
        <div className="flex justify-center mt-4">
          <button
            onClick={onOpenImage}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Layer 1 Comparisons
          </button>
        </div>
      )}
    </div>
  </motion.div>
);

const Home = () => {
  const [isImageOpen, setImageOpen] = useState(false);

  const handleOpenImage = () => {
    setImageOpen(true);
  };

  const handleCloseImage = (event) => {
    // Close modal if clicking outside the image container
    if (event.target.id === 'modal-background') {
      setImageOpen(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center text-white p-4 pb-16 w-full">
      {/* Stylish Header */}
      <div className="w-full text-center mb-2">
        <h1 className="text-3xl md:text-4xl font-bold text-center text-white mb-6 flex items-center justify-center">
          <img src={SEILogo} alt="SEI Logo" className="w-8 h-8 mr-2" />
          SEIGuide.xyz - Your Ultimate Guide to the Sei Blockchain
        </h1>
      </div>

      {/* Home Card section */}
      <div className="grid grid-cols-1 justify-center w-full max-w-5xl mb-8">
        {homeData.map((card, index) => (
          <HomeCard
            key={index}
            {...card}
            isSecondCard={index === 1} // Mark the second card
            onOpenImage={index === 1 ? handleOpenImage : undefined} // Add the image button only for the second card
          />
        ))}
      </div>

      {/* Image Pop-Up */}
      {isImageOpen && (
        <div
          id="modal-background"
          className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
          onClick={handleCloseImage}
        >
          <div className="relative bg-gray-900 p-6 rounded-lg shadow-lg max-w-4xl w-11/12 h-5/6 flex items-center justify-center">
            <img src={specsImage} alt="Specs" className="max-w-full max-h-full object-contain rounded" />
            <button
              onClick={() => setImageOpen(false)}
              className="absolute top-4 right-4 bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
