// src/data/homeData.js
export const homeData = [
  {
    title: "Our Paw-some Mission!",
    description: "With tails wagging and paws in motion, the Crafty Canines pack is all about helping grow the Sei chain! We're here to fetch the best tools, bark out knowledge, and lend a paw to get everyone involved in this howlin' good ecosystem. Come run with us and dig into everything Sei has to offer as we build a fur-tastic, tail-waggin' blockchain community together!",
  },
  {
    title: "Why Sei Blockchain?",
    description: [
	  "🌟 Community-Driven: A thriving ecosystem of developers, builders, and enthusiasts.",
      "💡 Grant Program: $10 million available to support innovative projects and empower creators.",
      "🚀 High Throughput: Process thousands of transactions per second.",
      "⚡ Low Latency: Transactions finalized in under a second.",
      "🔗 Interoperability: Easily connects with other Cosmos chains.",
      "💰 Cost Efficiency: Ultra-low transaction fees.",
      "🔒 Security: Built on robust Tendermint Core.",
      "🛠️ EVM Compatibility: Supports Ethereum-based smart contracts and dApps.",

    ],
  },
];
